<template>
  <div id="all-events-list">
    <v-overlay :value="DeleteLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="py-0">
      <v-speed-dial
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
        v-if="SpeedDialFlag"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
        <v-btn fab dark small color="indigo" @click.prevent="addRecord">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
      </v-speed-dial>
    </v-container>
    <v-dialog v-model="previewRecordPrompt" persistent max-width="75%">
      <events-preview
        pageTitle="Events Preview"
        pageDescription="Events Preview"
        :previewRecordPrompt="previewRecordPrompt"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
        :recordData="selectedData"
        v-if="previewRecordPrompt"
      ></events-preview>
    </v-dialog>
    <v-dialog v-model="scoreRecordPrompt" persistent max-width="75%">
      <events-score-preview
        pageTitle="Events Score"
        pageDescription="Validate 100% efficiency events"
        :scoreRecordPrompt="scoreRecordPrompt"
        @hideScoreRecordPrompt="hideScoreRecordPrompt"
        :recordData="selectedData"
        v-if="scoreRecordPrompt"
      ></events-score-preview>
    </v-dialog>
    <v-card>
      <v-card-title class="text-h5">
        <h2>100% Efficiency Status</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>List of 100% efficiency status of LOM</h4>
      </v-card-subtitle>

      <v-card-text>
        <v-row wrap>
          <v-col align="start" cols="12" md="12">
            <div class="notes-content">
              <!-- <p><span class="text-danger">*</span> indicates required field</p> -->
            </div>
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              elevation="30"
              shaped
              tile
              small
              class="font-size-h6 mr-3 white--text"
              @click.prevent="refreshPageData"
              color="#a4c639"
              :loading="SearchFlag"
            >
              Refresh
            </v-btn>
          </v-col>
        </v-row>
        <p></p>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0" v-if="tableData1.length == 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>No records found.</h3>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <h3>{{ tableData1.length }} records found.</h3> -->
            </v-col>
          </v-row>

          <v-row wrap align="center">
            <!-- <v-col align="center" cols="12" md="4">
              <img
                width="100%"
                contain
                :src="tableOptions1.EfficiencyImagePath"
                :alt="tableOptions1.EfficiencyImagePath"
                v-if="tableOptions1.EfficiencyImagePath != ''"
              />
            </v-col> -->

            <v-col align="center" cols="12" md="4" offset-md="2">
              <v-card
                dark
                hover
                :color="`${
                  tableOptions1.EfficiencyFlag ? '#0f9d58' : '#f4b400'
                }`"
              >
                <v-card-title class="card-title">
                  100 % Efficiency
                </v-card-title>
                <v-card-subtitle>
                  <br /><br />
                  <h2 class="card-subtitle">
                    {{ tableOptions1.EfficiencyStatusText }}
                  </h2>
                </v-card-subtitle>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-col>

            <v-col align="center" cols="12" md="4">
              <v-card
                :color="`${
                  tableOptions1.EfficiencyFlag ? '#0f9d58' : '#f4b400'
                }`"
                dark
                hover
              >
                <v-card-title class="card-title">
                  100% Efficiency <br />
                  Points
                </v-card-title>
                <v-card-subtitle>
                  <br /><br />
                  <v-expansion-panels>
                    <v-expansion-panel tile>
                      <v-expansion-panel-header
                        :color="`${
                          tableOptions1.EfficiencyFlag ? '#0f9d58' : '#f4b400'
                        }`"
                      >
                        <p class="card-subtitle">
                          {{ tableOptions1.TotalPoints }}
                        </p>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content
                        :color="`${
                          tableOptions1.EfficiencyFlag ? '#0f9d58' : '#f4b400'
                        }`"
                      >
                        <h4>
                          Compulsary Points:
                          {{ tableOptions1.CompulsaryPoints }}
                        </h4>
                        <h4>Bonus Points: {{ tableOptions1.BonusPoints }}</h4>
                        <h4>
                          Optional Points: {{ tableOptions1.OptionalPoints }}
                        </h4>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-subtitle>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-col>

            <!-- <v-col align="center" cols="12" md="3">
              <v-card color="#9932cc" dark hover>
                <v-card-title class="card-title">
                  Bonus <br />
                  Points
                </v-card-title>
                <v-card-subtitle>
                  <br /><br />
                  <p class="card-subtitle">{{ BonusPoints }}</p>
                </v-card-subtitle>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-col>

            <v-col align="center" cols="12" md="3">
              <v-card color="#fa8072" dark hover>
                <v-card-title class="card-title">
                  Optional <br />
                  Points
                </v-card-title>
                <v-card-subtitle>
                  <br /><br />
                  <p class="card-subtitle">{{ OptionalPoints }}</p>
                </v-card-subtitle>
                <v-card-actions> </v-card-actions>
              </v-card>
            </v-col> -->
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <!-- <v-btn v-if="DeleteFlag == 1" color="error" class="mr-4" @click="deleteRecords">
                  Delete
                </v-btn> -->
              <p></p>
              <json-excel
                v-if="
                  tableData1.length > 0 &&
                  tableOptions1.JsonDownloadButtonFlag == true
                "
                :name="tableOptions1.ExcelFileName"
                :data="tableData1"
                :fields="tableOptions1.JsonExcelFields"
              >
                <v-btn color="info" class="mr-4"> Download </v-btn>
              </json-excel>
            </v-col>
            <p></p>
          </v-row>
          <v-container class="py-0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  v-if="ExcelDownloadFlag == true && ExcelFileFlag == false"
                  @click.prevent="generateReportExcel"
                  :loading="ExcelLoadingFlag"
                  target="_blank"
                  color="#ff7f50"
                >
                  Generate Report
                </v-btn>
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  v-if="ExcelFileName != '' && ExcelFileFlag == true"
                  :href="ExcelFileName"
                  target="_blank"
                  color="#2fb65a"
                >
                  <v-icon dark> mdi-download </v-icon> Download Report
                </v-btn>
              </v-col>
            </v-row>
            <p></p>
          </v-container>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected"
                :search="search"
                :loading="TableLoadingFlag"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                    >{{ item.ActiveStatusTxt }}</v-chip
                  >
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.PreviewFlag == true">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="blue"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.EditFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="primary"
                        @click="editData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.DeleteFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="red"
                        @click="deleteConfirmAlert(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span> Delete </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.ScoreFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="scoreData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-document-edit
                      </v-icon>
                    </template>
                    <span> Edit </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import companyConfig from "@/company_config.json";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import LomPresidentAssign from "@/view/pages/erp/lom-president/LomPresidentAssign.vue";
import EventsPreview from "@/view/pages/erp/events/EventsPreview.vue";
import EventsScorePreview from "@/view/pages/erp/events-score/EventsScorePreview.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    LomPresidentAssign,
    EventsPreview,
    EventsScorePreview,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      SpeedDialFlag: false,
      TableLoadingFlag: false,
      DeleteLoadingFlag: false,

      ExcelDownloadFlag: false,
      ExcelLoadingFlag: false,
      ExcelFileFlag: false,

      rows: [],

      Modules: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      DownloadFlag: 0,
      ExcelFileName: "",
      excel_fields: {
        "Lom Id": "LomId",
        "Zone Id": "ZoneId",
        "Lom Name": "LomName",
        "President Name": "LomPresidentFullName",
        "LOM NAME": "LomName",
        "Mobile No": "LomPresidentMobileNo",
        "Email Id": "LomPresidentEmailid",
      },

      recordToEdit: "",
      record: {},
      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
      scoreRecordPrompt: false,
      editPageDescription: "Assign LOM President for JCI India",

      selectedData: {},

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      zone: {},
      lom: {},

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      MonthCodeRules: [],
      MonthCode: "",
      MonthCodeOptions: [],
      MonthCodeOptionsLoading: false,

      AreaCodeRules: [(v) => !!v || "Area is required"],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      RegionCodeRules: [],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      LomCodeRules: [],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      EventTypeRules: [],
      EventType: "",
      EventTypeOptions: [
        { value: "", text: "--All--" },
        { value: 1, text: "National Events" },
        { value: 2, text: "Zone Events" },
        { value: 3, text: "LOM Events" },
      ],
      EventTypeOptionsLoading: false,

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      DateModal1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      DateModal2: false,

      EfficiencyIdRules: [],
      EfficiencyId: "",
      EfficiencyIdOptions: [],
      EfficiencyIdOptionsLoading: false,
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("watch ZoneCode");
      this.LomCode = "";
      this.getLomCodeOptions();
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LoadingFlag = false;
      this.LomCodeOptionsLoading = false;
    },
    EfficiencyIdOptions: function () {
      console.log("watch EfficiencyIdOptions");
      this.LoadingFlag = false;
      this.EfficiencyIdOptionsLoading = false;
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/lom/create";
      var params = {};
      this.pageRedirect(params, url);
    },
    editData(tr) {
      console.log("editData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var url = "/events/edit";
        var id = tr.JciEventId;
        console.log("id=" + id);
        var params = {
          id: id,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one event to edit";
        this.toast("error", message);
      }
    },
    resetForm() {
      this.$refs.form2.reset();
      this.tableData1 = [];
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      this.searchForm();
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.searchForm();
    },
    editAlert(tr) {
      console.log("editAlert called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var LomId = tr.LomId;
        console.log("LomId=" + LomId);
        this.record = tr;
        this.recordToEdit = LomId;
        this.editPageDescription =
          "Assign 2021 LOM President for " + tr.LomName;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to modifiy";
        this.toast("error", message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    hideScoreRecordPrompt() {
      console.log("hideScoreRecordPrompt called");
      this.scoreRecordPrompt = false;
      this.searchForm();
    },
    scoreData(tr) {
      console.log("scoreData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.scoreRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      // this.JciYearCodeOptionsLoading = true;
      this.searchForm();
      // this.getEfficiencyIdOptions();
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      var areaCode = this.AreaCode;
      console.log("yearCode=" + yearCode + ", areaCode=" + areaCode);
      if (yearCode != "") {
        this.LoadingFlag = true;
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      var RegionCode = this.RegionCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", ZoneCode=" +
          ZoneCode +
          ", RegionCode=" +
          RegionCode
      );
      if (YearCode != "" && ZoneCode != "") {
        this.LoadingFlag = true;
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          AllOption: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getEfficiencyIdOptions() {
      console.log("getEfficiencyIdOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.LoadingFlag = true;
        this.EfficiencyIdOptionsLoading = true;
        var selectbox1_source = "EfficiencyId";
        var selectbox1_destination = "EfficiencyIdOptions";
        var selectbox1_url = "api/year-wise-efficiency/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          Year: yearCode,
          AllOption: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var message = "";

      // var validate1 = this.$refs.form2.validate();
      var validate1 = true;
      console.log("validate1=" + validate1);

      var JciYearCode = this.JciYearCode;
      console.log({ JciYearCode });

      var LomCode = this.LomCode;
      console.log({ LomCode });

      if (validate1 && JciYearCode != "" && LomCode != "") {
        this.SearchFlag = true;
        this.tableData1 = [];
        this.TotalPoints = 0;
        this.CompulsaryPoints = 0;
        this.BonusPoints = 0;
        this.OptionalPoints = 0;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-efficiency/lom-status";
        var upload = {
          UserInterface: 1,
          Year: JciYearCode,
          Lom: LomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            console.log({ records });

            if (flag == 1) {
              var options = records.TableOptions;
              console.log({ options });

              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableOptions1 = options;
              thisIns.tableData1 = records.TableData;
              thisIns.toast("success", output);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (JciYearCode == "") {
          message += "Year should not be empty. ";
        }
        if (LomCode == "") {
          message += "LOM should not be empty. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    generateReportExcel() {
      console.log("generateReportExcel is called");

      // var validate1 = this.$refs.form2.validate();
      var FromDate = "2021-08-04";
      var ToDate = "2021-08-05";
      var validate1 = true;
      console.log(
        "validate1=" +
          validate1 +
          ", FromDate=" +
          FromDate +
          ", ToDate=" +
          ToDate
      );

      if (validate1) {
        this.ExcelFileName = "";
        this.ExcelLoadingFlag = true;
        this.ExcelFileFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url =
          server_url + "api/reports/directory-information/generate-excel";
        var upload = {
          UserInterface: 1,
          YearCode: this.JciYearCode,
          ZoneCode: this.ZoneCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()

            thisIns.ExcelLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ExcelFileName = records.ExcelFileName;
              thisIns.ExcelDownloadFlag = records.ExcelDownloadFlag;
              thisIns.ExcelFileFlag = true;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ExcelLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.toast("error", message);
      }
    },
    deleteConfirmAlert(tr) {
      console.log("deleteConfirmAlert called");
      var message = "";
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));

      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/> Event Type <b> " + tr.OrganizationTypeName + "</b>";
        htmlTxt += "<br/> Portfolio <b> " + tr.PortfolioShortCode + "</b>";
        htmlTxt += "<br/> Event name <b> " + tr.JciEventName + "</b>";
        htmlTxt += "<br/> 100% efficiency <b> " + tr.SubCriteriaTxt + "</b>";
        htmlTxt +=
          "<br/> Points <b> " +
          tr.PointsTypeName +
          " - " +
          tr.YearwiseEfficiencyPoints +
          " pts </b>";

        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteData(tr.JciEventId);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (n1 == 0) {
          message += "Kindly select one record to delete. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    deleteData(EventId) {
      console.log("deleteData is called");

      console.log("EventId=" + EventId);

      if (EventId > 0) {
        this.DeleteLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-event-score/destroy";
        var upload = {
          UserInterface: 1,
          EventId: EventId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.DeleteLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            table = response.data.TableContent;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Event Id should not be empty. ";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    var CurrentJciYearId = this.$session.get("CurrentJciYearId");
    // CurrentJciYearId = 11;
    CurrentJciYearId =
      CurrentJciYearId == (null || undefined) ? "" : CurrentJciYearId;
    console.log({ CurrentJciYearId });
    this.JciYearCode = 12;

    var LomId = this.$session.get("LomId");
    // LomId = 11;
    LomId = LomId == (null || undefined) ? "" : LomId;
    console.log({ LomId });
    this.LomCode = LomId;

    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#all-events-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-family: "Roboto", serif;
    font-size: 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-family: "Roboto", serif;
    font-size: 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .card-title {
    font-size: 25px;
    font-family: "Lora", serif;
    padding: 20px;
    text-align: center;
    word-wrap: break-word;
  }
  .card-subtitle {
    font-size: 60px;
    font-family: "Lobster", cursive;
    color: white;
    text-align: center;
  }
  .card-subtitle1 {
    font-size: 25px;
    font-family: "Libre Baskerville", serif;
    color: white;
    text-align: center;
    line-height: 40px;
  }
}
</style>